import {
    createRouter,
    createWebHistory
} from 'vue-router'


const routes = [{
        path: '/',
        redirect: '/index'
        // redirect: '/NewsCenter'
    },
    {
        path: '/index',
        meta: {
            title: '首页'
        },
        component: () => import('@/views/index/index')
    },
    {
        path: '/Bidding',
        meta: {
            title: '招标信息'
        },
        component: () => import('@/views/Bidding/Bidding')
    },
    {
        path: '/Purchasing',
        meta: {
            title: '采购信息'
        },
        component: () => import('@/views/Purchasing/Purchasing')
    },
    {
        path: '/Financial',
        meta: {
            title: '金融服务'
        },
        component: () => import('@/views/Financial/Financial')
    },
    {
        path: '/NewsCenter',
        meta: {
            title: '新闻中心'
        },
        component: () => import('@/views/NewsCenter/NewsCenter')
    },
    {
        path: '/Member',
        meta: {
            title: '会员权益'
        },
        component: () => import('@/views/Member/Member')
    },
    {
        path: '/Contact',
        meta: {
            title: '联系我们'
        },
        component: () => import('@/views/Contact/Contact')
    },
    {
        path: '/Equity',
        meta: {
            title: '会员权益'
        },
        component: () => import('@/views/Equity/Equity')
    },
    {
        path: '/BiddingInfo',
        meta: {
            title: '招标信息详情'
        },
        component: () => import('@/views/BiddingInfo/BiddingInfo')
    },
    {
        path: '/ListOfNews',
        meta: {
            title: '新闻列表页'
        },
        component: () => import('@/views/ListOfNews/ListOfNews')
    },
	{
	    path: '/ListOfNews1',
	    meta: {
	        title: '帮助中心'
	    },
	    component: () => import('@/views/ListOfNews/ListOfNews1')
	},
	{
	    path: '/ListOfNews2',
	    meta: {
	        title: '操作流程'
	    },
	    component: () => import('@/views/ListOfNews/ListOfNews2')
	},
	{
	    path: '/ListOfNews3',
	    meta: {
	        title: '下载中心'
	    },
	    component: () => import('@/views/ListOfNews/ListOfNews3')
	},
    {
        path: '/InfoView',
        meta: {
            title: '咨询详情'
        },
        component: () => import('@/views/InfoView/InfoView')
    },
    {
        path: '/CaApply',
        meta: {
            title: 'CA办理'
        },
        component: () => import('@/views/Ca/CaApply')
    }
]


const router = createRouter({
    routes,
    history: createWebHistory()
})


router.beforeEach((to, from, next) => {
    // if (to.meta.title) {
    //     document.title = to.meta.title
    // }
    document.title = "文安县城发集团集采平台"
    document.documentElement.scrollTop = 0
    next()
})

export default router